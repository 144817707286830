@use 'core/variables';
@use 'core/mixins';
@use 'core/reboot';

// Core Components
@use 'core/layout';
@use 'core/buttons';
@use 'core/checkboxes';
@use 'dashboard/cards';
@use 'core/custom-forms';
@use 'core/switches';
@use 'core/radios';
@use 'dashboard/forms';
@use 'core/input-group';
@use 'core/list-group';
@use 'core/nav';
@use 'core/images';
@use 'dashboard/navbar';
@use 'core/badges';
@use 'core/pagination';
@use 'core/info-areas';
@use 'core/type';
@use 'core/tabs';
@use 'core/footers';
@use 'core/headers';
@use 'core/togglebutton';
@use 'core/ripples';
@use 'dashboard/rtl';
@use 'dashboard/sidebar-and-main-panel';
@use 'dashboard/timeline';
@use 'dashboard/tables';
@use 'dashboard/misc';
@use 'dashboard/pages';

// Components for PRO
@use 'dashboard/social-buttons';

//plugin css
@use 'plugins/animate';
@use 'plugins/fullcalendar';

@use 'dashboard/responsive';

@use '@angular/material' as mat;

:root {
    --mdc-typography-body1-font-size: 14px;
    --mdc-typography-body1-letter-spacing: normal;
    --mdc-typography-body2-letter-spacing: normal;
    --mdc-typography-button-letter-spacing: normal;
    --mdc-typography-caption-letter-spacing: normal;
    --mdc-filled-button-label-text-tracking: normal;
    --mdc-switch-handle-surface-color: #0d47a1;
    --mdc-theme-text-icon-on-background: #000000de;
    --mat-option-label-text-size: 14px !important;
    --mat-option-label-text-tracking: 0 !important;
    --mat-menu-item-label-text-size: 14px !important;
    --mat-menu-item-label-text-tracking: 0 !important;
    --mdc-filled-text-field-label-text-size: 14px;
    --mdc-filled-text-field-label-text-tracking: normal;
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-weight: 300;
    --mat-select-trigger-text-tracking: normal;
    --mat-form-field-container-text-size: 14px;
    --mat-form-field-container-text-tracking: normal;
    --mat-form-field-subscript-text-tracking: normal;
    --mdc-filled-text-field-active-indicator-color: #d2d2d2;
    --mdc-filled-text-field-hover-active-indicator-color: #a8a8a8;
    --mdc-text-button-label-text-tracking: normal;
    --mdc-list-list-item-focus-state-layer-opacity: 0.1 !important;
    --mat-bottom-sheet-container-background-color: white;
    --mat-expansion-container-text-weight: 300;
    --mat-expansion-container-text-tracking: normal;
    --mdc-protected-button-label-text-tracking: normal;
    --mdc-list-list-item-leading-icon-color: rgb(51, 51, 51);
    --mdc-list-list-item-hover-leading-icon-color: rgb(51, 51, 51);
    --mat-sys-surface: #ffffff;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
    --mat-full-pseudo-checkbox-selected-icon-color: #0d47a1;
}

.table {
    --bs-table-bg: initial;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: initial !important;
}

.mat-mdc-snack-bar-label {
    display: flex;
    justify-content: center;
}

.mat-mdc-snack-bar-action {
    .mdc-button__label {
        color: white;
    }
}

.error-snackbar {
    &.mat-mdc-snack-bar-container {
        .mdc-snackbar__surface {
            --mdc-snackbar-container-color: #c20000;
            --mat-mdc-snack-bar-button-color: #fff;
            --mdc-snackbar-supporting-text-color: #fff;
        }
    }
}

// I don't think this is needed anymore!
.mat-tooltip {
    font-weight: 400;
    font-size: 12px !important;
    max-width: 400px !important;
}

mat-tooltip-component {
    .mat-mdc-tooltip {
        --mdc-plain-tooltip-supporting-text-tracking: normal;
    }

    .multi-line {
        white-space: pre-line;
    }

    .multi-line-left {
        white-space: pre-line;

        .mdc-tooltip__surface {
            text-align: left;
        }
    }
}

mat-form-field {
    width: 100%;

    .mdc-text-field--filled {
        background-color: transparent !important;
    }

    .mdc-line-ripple::after {
        transition-duration: 500ms !important;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: #a8a8a8;
    }

    .mdc-line-ripple--deactivating::after {
        transition-duration: 200ms !important;
    }

    .mdc-floating-label {
        color: #0d47a1 !important;
    }

    .form-control:disabled {
        background-color: transparent;
        cursor: not-allowed !important;
    }

    &.mat-form-field-disabled {
        cursor: not-allowed !important;
        opacity: 0.75;
    }

    .mat-mdc-form-field-bottom-align::before {
        display: none;
    }

    textarea.form-control:focus,
    input.form-control:focus {
        background-color: transparent;
    }

    input:disabled {
        cursor: not-allowed !important;
        opacity: 0.75;
    }
}

.mat-mdc-menu-content {
    .mat-mdc-menu-item {
        .mat-icon {
            color: #0000008a;
        }

        .mdc-list-item__primary-text {
            color: #000000de;
        }
    }
}

.mdc-tooltip__surface {
    font-weight: 400;
    font-size: 12px !important;
    max-width: 400px !important;
}

button.mat-mdc-icon-button.mat-mdc-button-base {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 8px;
}

.mat-mdc-form-field-icon-suffix {
    button.mat-mdc-icon-button.mat-mdc-button-base {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        padding: 0;
        margin-right: 4px;

        mat-icon {
            width: 20px;
            height: 20px;
            font-size: 20px;
        }
    }
}

.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-size: 14px !important;
}

div.md-drppicker {
    background-color: white;
}

mat-dialog-container {
    border-radius: 4px;

    .mdc-dialog__container {
        .mdc-dialog__title {
            padding-top: 24px;
            padding-bottom: 20px;
        }

        .mdc-dialog__title::before {
            display: none;
        }

        .mdc-dialog__content {
            color: #000000de;
            letter-spacing: normal;
            padding-bottom: 0;
            font-weight: 300;
        }
    }
}

mat-slide-toggle {
    cursor: pointer;

    .mdc-form-field {
        .mdc-switch {
            margin-left: 8px;
        }

        label {
            margin-left: 8px;
            user-select: none;
            margin-bottom: 0;
            cursor: pointer;
        }

        .mdc-label {
            letter-spacing: normal;
        }
    }

    .mdc-switch .mdc-switch__ripple {
        width: 40px !important;
        height: 40px !important;
    }

    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
        background-color: #0d47a1 !important;
    }

    .mdc-switch:enabled .mdc-switch__track::after {
        background-color: #0d47a1 !important;
        opacity: 0.5;
    }

    .mdc-switch.mdc-switch--selected:enabled:hover .mdc-switch__ripple::after,
    .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
        background-color: #0d47a1 !important;
    }

    .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
        background-color: #9b9b9b !important;
    }
}

mat-selection-list {
    mat-list-option {
        font-size: 14px;
    }
}

// TO-DO: hier moet er wss veel weg
mat-list,
mat-selection-list {
    .mdc-list-item__primary-text,
    .mdc-list-item__secondary-text {
        letter-spacing: normal !important;
        color: #000000de;
    }

    .mdc-list-item__primary-text {
        font-weight: 400;
    }

    .mdc-list-item__secondary-text {
        font-weight: 300;
    }

    .mdc-list-item__start {
        align-self: center !important;
    }

    p {
        margin-bottom: 0;
    }

    mat-list-item + mat-list-item {
        margin-top: 8px;
    }

    .mdc-list-item__start {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mdc-list-item--with-leading-icon .mdc-list-item__start {
        margin: 0 24px !important;
    }

    h4 {
        margin: initial;
    }
}

@include mat.chips-overrides(
    (
        outline-color: #0d47a1,
        disabled-outline-color: #0d47a1,
        label-text-color: #0d47a1,
        with-trailing-icon-trailing-icon-color: #0d47a1,
        label-text-weight: 500,
    )
);

mat-selection-list {
    .mat-mdc-list-item .mdc-list-item__primary-text {
        font-size: 14px !important;
    }
}

.mat-list-item-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    background: transparent !important;
    pointer-events: initial !important;
    user-select: none;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #d2d2d2 !important;
}

.mat-form-field-ripple {
    transform: scaleX(0) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
    transform: scaleX(1) !important;
    transition: transform 300ms ease-in-out !important;
}

.mat-calendar-table-header th {
    line-height: normal;
}

.fc {
    a {
        color: #3c4858;
    }

    thead a {
        color: #0d47a1;
    }
}

mat-checkbox {
    user-select: none;

    label {
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
}

mat-divider {
    --mat-divider-width: 1px;
    --mat-divider-color: rgba(0, 0, 0, 0.12);
}

.mat-slide-toggle {
    .mat-slide-toggle-label {
        user-select: none;
    }
}

.input-label {
    display: flex;
    align-items: center;
    color: #0d47a1;
    margin-bottom: 0.5rem;

    span {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    mat-icon {
        margin-right: 4px;
    }
}

mat-select,
.mat-select-panel {
    .select-option,
    .mat-option-text {
        display: flex;
        align-items: center;

        mat-icon {
            position: absolute;
            color: #5f6368;
        }

        span {
            position: absolute;
            left: 32px;
        }
    }
}

.center-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;

    tourlife-button + button {
        margin-left: 8px;
    }
}

.extended-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    tourlife-disc + tourlife-disc {
        margin-left: 20px;
    }
}

.pick-button {
    font-weight: 500;
    background-color: #0d47a1;
    color: white;
    padding: 4px 6px;
    border-radius: 4px;
    margin-left: 4px;
    font-size: 12px;
    user-select: none;
    cursor: pointer;
    border: none;
    margin-left: 4px;

    &:disabled {
        pointer-events: none;
        background-color: gray;
    }

    &:focus {
        outline: solid #0d47a1 2px !important;
        border: solid 1px white;
    }
}

google-map {
    a[href^="http://maps.google.com/maps"],
        a[href^="https://maps.google.com/maps"]
    {
        display: none !important;
    }

    .map-container {
        margin: 0 auto;
    }

    .gmnoprint a,
    .gmnoprint span,
    .gm-style-cc {
        display: none;
    }
}

// json-formater-js styles
.json-formatter-object {
    overflow-wrap: break-word;
}

// Fix for Quill
quill-editor {
    .ql-editor {
        font-weight: var(--mat-form-field-container-text-weight);
    }

    &:hover {
        background-color: #f8f8f8;

        .ql-container.ql-snow {
            border-bottom-color: #a8a8a8;
        }
    }

    &.ql-focus {
        background-color: #f1f1f1;
    }

    .ql-toolbar {
        &.ql-snow {
            border: none;
            padding-top: 16px;
        }

        .material-icons {
            font-size: 18px;
        }
    }

    .ql-container.ql-snow {
        border: none;
        border-bottom: solid 1px #d2d2d2;
    }

    .ql-toolbar.ql-snow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: Roboto, sans-serif;
        gap: 4px 12px;
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin-right: initial;
    }

    .ql-snow .ql-picker.ql-header {
        width: initial;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        position: initial;
        margin-top: initial;
    }

    .ql-formats .ql-picker-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ql-tooltip {
        font-weight: var(--mat-form-field-container-text-weight);

        .ql-action,
        .ql-remove {
            font-weight: 500;
        }
    }
}

.ql-container {
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

// For backwards compatibility (since upgrading to @angular/material 17)
.mat-dialog-title {
    font-size: var(--mdc-dialog-subhead-size, 1rem);
    font-family: var(--mdc-dialog-subhead-font, Roboto, sans-serif);
    font-weight: var(--mdc-dialog-subhead-weight, 400);
    line-height: var(--mdc-dialog-subhead-line-height, 1.5rem);
    letter-spacing: var(--mdc-dialog-subhead-tracking, 0.03125em);
}

:root {
    --gray1: hsl(0, 0%, 99%);
    --gray2: hsl(0, 0%, 97.3%);
    --gray3: hsl(0, 0%, 95.1%);
    --gray4: hsl(0, 0%, 93%);
    --gray5: hsl(0, 0%, 90.9%);
    --gray6: hsl(0, 0%, 88.7%);
    --gray7: hsl(0, 0%, 85.8%);
    --gray8: hsl(0, 0%, 78%);
    --gray9: hsl(0, 0%, 56.1%);
    --gray10: hsl(0, 0%, 52.3%);
    --gray11: hsl(0, 0%, 43.5%);
    --gray12: hsl(0, 0%, 9%);

    --grayA1: hsla(0, 0%, 0%, 0.012);
    --grayA2: hsla(0, 0%, 0%, 0.027);
    --grayA3: hsla(0, 0%, 0%, 0.047);
    --grayA4: hsla(0, 0%, 0%, 0.071);
    --grayA5: hsla(0, 0%, 0%, 0.09);
    --grayA6: hsla(0, 0%, 0%, 0.114);
    --grayA7: hsla(0, 0%, 0%, 0.141);
    --grayA8: hsla(0, 0%, 0%, 0.22);
    --grayA9: hsla(0, 0%, 0%, 0.439);
    --grayA10: hsla(0, 0%, 0%, 0.478);
    --grayA11: hsla(0, 0%, 0%, 0.565);
    --grayA12: hsla(0, 0%, 0%, 0.91);
}

a {
    text-decoration: none;
}
